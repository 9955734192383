<template>
  <div class="services-container flex flex-col gap-8 p-4">
    <!-- Main Services -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <ServiceComponent
        v-for="service in services.mainServices"
        :key="service.name"
        v-bind="service"
      />
    </div>
    
    <!-- Micro Services -->
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <ServiceComponent
        v-for="service in services.microServices"
        :key="service.name"
        v-bind="service"
      />
    </div>
  </div>
</template>

<script setup>
import ServiceComponent from "@/components/navigation/Service.vue";

const services = {
  mainServices: [
    {
      title: "aktio indsigt",
      name: "indsigt",
      description: "Aktio Indsigt understøtter ekstrahering, jura, søgning og gennemgang af enkelte dokumenter  eller større sager. Løsningen kan bruges til behandling af aktindsigt, eller enkle formål at rense materiale for personoplysninger.",
      disabled: false,
      iconName: "fa-regular fa-file-lines",
      link: "/light/tasks",
      isMain: true
    },
    {
      title: "aktio gdpr",
      name: "gdpr",
      description: "Upload filer af alle typer og få en rapport om, hvor der er GDPR sensitivt data.",
      disabled: false,
      iconName: "fa-magnifying-glass-chart",
      link: "/gdpr/",
      isMain: true
    }
  ],
  microServices: [
    {
      title: "aktio dokumentklip",
      name: "dokumentklip",
      description: "Klip et dokument i flere dele eller samle dokumenter efter valg af MB.",
      disabled: false,
      iconName: "fa-scissors",
      link: "/",
      isMain: false
    },
    {
      title: "aktio ocr",
      name: "ocr",
      description: "Værktøj til at OCR behandle og optimere PDF filer.",
      disabled: false,
      iconName: "fa-laptop-file",
      link: "/",
    },
    {
      title: "aktio konverter",
      name: "konverter",
      description: "Konverter filer til PDF. Dette inkludere Word, Excel, PowerPoint, PNG, Mail med vedhæftninger og mange flere.",
      disabled: false,
      iconName: "fa-file-export",
      link: "/",
    },
    {
      title: "Aktio Indsigt (depricated)",
      name: "indsigt",
      description: "Forrige version",
      disabled: false,
      iconName: "fa-regular fa-file-lines",
      link: "/light/tasks",
    },
  ]
};
</script>

<style>
</style>
