<template>
  <div
    :class="[
      'service p-4 rounded-lg text-center transition duration-200',
      isMain ? 'h-80' : 'h-64',
      disabled 
        ? 'bg-gray-200 cursor-not-allowed' 
        : `${isMain ? 'bg-gray-200' : 'bg-gray-200'} shadow hover:text-aktio-pink-500 hover:shadow-lg hover:bg-gray-300 cursor-pointer`
    ]"
    @click="goToService()"
  >
    <div class="w-full object-cover rounded-md mb-2 mt-4 center"
         :class="[isMain ? 'h-20' : 'h-16']">
      <font-awesome-icon
        :size="isMain ? '4x' : '3x'"
        :icon="iconName"
        color="#092853"
      />
    </div>
    <h2
      :class="[
        'service-title font-semibold mb-2',
        isMain ? 'text-4xl' : 'text-3xl',
        disabled ? 'text-gray-500' : 'text-gray-800'
      ]"
      style="font-family: 'Outfit', sans-serif;"
    >
      aktio <span class="text-aktio-pink-500">{{ name }}</span>
    </h2>
    <p
      :class="[
        'service-description',
        disabled ? 'text-gray-400' : 'text-gray-600',
        isMain ? 'text-lg' : 'text-base'
      ]"
    >
      {{ description }}
    </p>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  link: {
    type: String,
    default: "/"
  },
  title: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  iconName: {
    type: String,
    required: true,
    default: "fa-file"
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isMain: {
    type: Boolean,
    default: false,
  }
});

const goToService = () => {
  window.location.href = props.link;
};
</script>

<style>
</style>
  